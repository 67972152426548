<script setup lang="ts">
import type { WithContext, ListItem, BreadcrumbList } from 'schema-dts';

import ChevronRight from '@/assets/icons/chevron_right.svg?raw';

export interface Props {
    crumbs?: ChapmanCrumb[];
    current: string;
}

const props = withDefaults(defineProps<Props>(), {
    crumbs: () => [],
});

const router = useRouter();
const appConfig = useAppConfig();
const dealersStore = useDealersStore();

const orderedCrumbs = computed(() => {
    if (props.crumbs && props.crumbs.length > 1)
        return [...props.crumbs].sort((a, b) => a.order - b.order);
    else return props.crumbs;
});

/**
 * Build the BreadcrumbsList schema
 */
const googleCrumbs = computed(() => {
    // Create the list of ListItem, first entry is always home page
    const listItems: ListItem[] = [
        {
            '@type': 'ListItem',
            position: 1,
            item: {
                '@id': `https://${dealersStore.siteUrl}`,
                name: `${dealersStore.siteName}`,
            },
        },
    ];

    // Loops through all known crumbs and create their ListItem
    let count = 2;
    for (const crumb of orderedCrumbs.value) {
        const link = router.resolve(crumb.link);
        listItems.push({
            '@type': 'ListItem',
            position: count,
            item: {
                '@id': `https://${dealersStore.siteUrl}${link.fullPath}`,
                name: crumb.display,
            },
        });
        count += 1;
    }

    // Create the BreadcrumbList with the required context
    const breadcrumbListSchema: WithContext<BreadcrumbList> = {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: listItems,
    };

    return {
        hid: 'breadcrumbs',
        type: 'application/ld+json',
        innerHTML: breadcrumbListSchema,
    };
});

useHead({
    script: [googleCrumbs.value],
});
</script>

<template>
    <div
        class="breadcrumb"
        :class="{
            uppercase: appConfig.breadcrumbsBarOptions.uppercase,
            'theme--bmw': appConfig.theme === 'bmw',
            'theme--audi': appConfig.theme === 'audi',
            'theme--vw': appConfig.theme === 'vw',
        }"
    >
        <CenterContent padding="0 20px" class="center-content">
            <NuxtLink :to="{ name: 'index' }" class="nuxt-link">
                Home
            </NuxtLink>

            <BaseIcon
                :icon-html="ChevronRight"
                aria-hidden="true"
                class="chevron-right"
            />

            <template
                v-for="(crumb, index) in orderedCrumbs"
                :key="`link-${index}`"
            >
                <NuxtLink :to="crumb.link" class="nuxt-link">
                    {{ crumb.display }}
                </NuxtLink>
                <BaseIcon
                    :icon-html="ChevronRight"
                    aria-hidden="true"
                    class="chevron-right"
                />
            </template>
            <h1>{{ current }}</h1>
        </CenterContent>
    </div>
</template>

<style lang="postcss" scoped>
.breadcrumb {
    background-color: var(--color-breadcrumb-bg);
    flex: none;
    color: var(--color-breadcrumb-text);

    &.uppercase {
        text-transform: uppercase;
    }

    & .center-content {
        display: flex;
        align-items: center;

        & .nuxt-link {
            line-height: 44px;
            color: var(--color-breadcrumb-text);
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            width: auto;

            &:hover {
                color: var(--color-accent);
            }
        }

        & .chevron-right {
            --base-icon-size: 26px;
            --base-icon-stroke-width: 3;

            padding: 0 5px;
        }

        & h1 {
            font-size: 16px;
            font-weight: var(--font-weight-bold);
            line-height: 44px;
            white-space: nowrap;
        }
    }

    &.theme--bmw,
    &.theme--audi {
        & .center-content {
            & h1 {
                font-weight: var(--font-weight-normal);
            }
        }
    }

    &.theme--vw {
        & .center-content {
            & h1 {
                font-family: var(--font-family-vw-head-regular);
            }
        }
    }
}

@media screen and (max-width: 530px) {
    .breadcrumb {
        & .center-content {
            & .nuxt-link,
            & .chevron-right {
                display: none;
            }

            & h1 {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                width: 100%;
            }
        }
    }
}

@media print {
    * {
        display: none;
    }
}
</style>
